import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { __product } from './__product'
import { Row, Col, Segmented, Divider } from 'antd'
import i from '../../../translations/i'
import { Titles } from '../../../layouts/Titles'
import { ProductMain } from './product_modules/ProductMain'
import { ProductImages } from './product_modules/ProductImages'
import { ProductMarketing } from './product_modules/ProductMarketing'
import { ProductProps } from './product_modules/ProductProps'
import { ProductWith } from './product_modules/ProductWith'
import { ProductWarehouses } from './product_modules/ProductWarehouses'
import { ProductFiles } from './product_modules/ProductFiles'
import { ProductText } from "./product_modules/ProductText"
import { ProductSet } from "./product_modules/ProductSet";
import { storeLocal } from "../../../helpers/stores";

export const Product = observer(() => {

    const { productID, categoryID }: any = useParams()

    useEffect(() => {
        if(productID > 0) {
          __product.getOne(productID)
        }
        if(categoryID > 0) __product.item.categories.push(categoryID)
    }, [])

    return(
        <>
            <form onSubmit={(e) => __product.sendForm(e)}>
                <Titles
                    title={i.t('product:editProduct')}
                    buttons={__product.item.product_id > 0 ? [
                      { title: i.t('product:back'), href: `/shop/catalog/product/${__product.item.product_id}` },
                      { title: i.t('def:actions.save'), htmlType: 'submit', type: 'primary' },
                    ] : [
                        { title: i.t('def:actions.save'), htmlType: 'submit', type: 'primary' },
                    ]}
                />
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <Segmented
                            options={[
                                { label: i.t('product:tabMain'), value: 'main' },
                                { label: i.t('product:text'), value: 'text' },
                                { label: i.t('product:tabImage'), value: 'images' },
                                { label: i.t('product:tabProps'), value: 'props', disabled: !__product.item.id || __product.item.id === 0 },
                                { label: i.t('product:forWiths'), value: 'with' },
                                { label: i.t('product:tabMarketing'), value: 'marketing' },
                                { label: i.t('product:storage'), value: 'warehouses' },
                                { label: i.t('def:Files'), value: 'files' },
                              { label: i.t('def:Product set'), value: 'set', disabled: storeLocal.get('pricing_plan') < 1 }
                            ]}
                            onChange={(e) => __product.changeTab(e)}
                            value={__product.item_tab}
                        />
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>
                    <Col span={24}>
                        {__product.item_tab === 'main' ? <ProductMain /> : <></>}
                        {__product.item_tab === 'text' ? <ProductText /> : <></>}
                        {__product.item_tab === 'images' ? <ProductImages /> : <></>}
                        {__product.item_tab === 'props' ? <ProductProps /> : <></>}
                        {__product.item_tab === 'with' ? <ProductWith /> : <></>}
                        {__product.item_tab === 'marketing' ? <ProductMarketing /> : <></>}
                        {__product.item_tab === 'warehouses' ? <ProductWarehouses /> : <></>}
                        {__product.item_tab === 'files' ? <ProductFiles /> : <></>}
                      {__product.item_tab === 'set' ? <ProductSet /> : <></>}
                    </Col>
                </Row>
            </form>
        </>
    )
})