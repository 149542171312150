import { makeAutoObservable } from 'mobx'
import hashController from '../../../template/controllers/hash.controller'
import i from '../../../../translations/i'
import { request } from '../../../../helpers/request'
import __select from '../../../../helpers/select_entity/__select.controller'
import dayjs from 'dayjs'

class promoController {
    constructor() {
        makeAutoObservable(this)
    }

    coupon = {
        id: 0,
        datestart: dayjs(),
        dateend: 0,
        text: '',
        countall: 0,
        birthday: 0,
        birthday_limit: 0
    }

    pagination = {
        total: 0,
        limit: 10,
        page: 1,
        offset: 0
    }

    coupons = []
    interval = {
        from: 0,
        to: 0
    }
    statistics = []

    breadcrumb = [
        { link: '/shop', value: i.t('crumb:shop') },
        { link: '/shop/promo', value: i.t('crumb:promo') }
    ]

    setPageNull() {
        this.pagination.page = 1
        this.pagination.offset = 0
    }

    setPage(page) {
        this.pagination.page = page
        if (page > 0) {
            this.pagination.offset =
                this.pagination.limit * page - this.pagination.limit
        } else {
            this.pagination.offset = 0
        }
        this.getCoupons()
    }

    setPageStat(page, couponID) {
        this.pagination.page = page
        if (page > 0) {
            this.pagination.offset =
                this.pagination.limit * page - this.pagination.limit
        } else {
            this.pagination.offset = 0
        }
        this.getCouponStatistics(couponID)
    }

    getCoupon(couponID) {
        if (couponID > 0) {
            request('get', `/product/promocode/${couponID}`)
                .then((response) => {
                    console.log(response)

                    response.datestart = response.datestart > 0 ? dayjs(response.datestart*1000) : 0
                    response.dateend = response.dateend > 0 ? dayjs(response.dateend*1000) : 0

                    this.coupon = response
                    this.coupon.onetouser = response.onetouser > 0
                    this.coupon.firstorder = response.firstorder > 0
                    this.coupon.birthday = response.birthday > 0

                    __select.select.value = this.coupon.value
                })
                .catch((response) => {})
        }
    }

    getCoupons() {
        request('get', `/product/promocode?limit=${this.pagination.limit}&offset=${this.pagination.offset}`)
            .then((response) => {
                this.pagination.total = response[1]
                this.coupons = response[0]
            })
            .catch((response) => {})
    }

    deleteCoupon(couponID) {
        if (window.confirm(i.t('def:confirms.delete'))) {
            let facts = {
                typestructure: 'ksPromocode'
            }

            request('delete', `/product/delete/${couponID}`, {
                data:
                    'typestructure=ksPromocode&hash=' +
                    hashController.setHashDelete(facts)
            })
                .then((response) => {
                    console.log(response)
                    this.getCoupons()
                })
                .catch((response) => {
                    console.log(response)
                })
        }
    }

    saveCoupon(e) {
        e.preventDefault()

        let facts = new FormData()
        facts.append('name', this.coupon.name)
        facts.append('code', this.coupon.code)
        facts.append('text', this.coupon.text)
        facts.append('countall', this.coupon.countall)
        facts.append(
            'count',
            this.coupon.countall > 0 ? this.coupon.countall : 1
        )

        if (this.coupon.countall === 0) facts.append('nocount', 1)
        if (this.coupon.countall > 0) facts.append('nocount', 0)

        facts.append(
            'datestart',
            this.coupon.datestart > 0
                ? dayjs(this.coupon.datestart).unix()
                : 0
        )
        facts.append(
            'dateend',
            this.coupon.dateend > 0 ? dayjs(this.coupon.dateend).unix() : 0
        )
        facts.append('onetouser', this.coupon.onetouser === true ? 1 : 0)
        facts.append('firstorder', this.coupon.firstorder === true ? 1 : 0)
        facts.append('birthday', this.coupon.birthday === true ? 1 : 0)
        facts.append('birthday_limit', this.coupon.birthday_limit ? this.coupon.birthday_limit : 0)
        facts.append('summcard', this.coupon.summcard)

        facts.append('value', this.coupon.type === 2 ? __select.select.value : this.coupon.value)
        facts.append('type', this.coupon.type)
        facts.append('hash', hashController.setHash(facts))

        request('post', `/product/promocode/${this.coupon.id}`, facts)
            .then((response) => {
                window.location = '/shop/promo'
            })
            .catch((response) => {
                console.log(response.response)
            })
    }

    onChange(name, value) {
        if (
            name === 'countall' &&
            value !== this.coupon.count &&
            value !== 0 &&
            this.coupon.id
        ) {
            if (!window.confirm(`${i.t('def:confirms.promo')} ${value}`)) {
                return false
            }
        }
        // if (name === 'datestart' || name === 'dateend') {
        //     value = Math.floor(Date.parse(value))
        // } else {
        // }

        if(name === 'code') value = value.toUpperCase()
        this.coupon[name] = value
    }

    onChangeInterval(name, value) {
        this.interval[name] = Math.floor(Date.parse(value))
        this.getCouponStatistics(this.coupon.id)
    }

    getCouponStatistics(couponID) {
        let intervals = ``
        if (this.interval.from > 0 && this.interval.to > 0) {
            intervals = `?filter[date_le]=${Math.floor(
                this.interval.to / 1000
            )}&filter[date_ge]=${Math.floor(this.interval.from / 1000)}`
        }

        request('get', `/product/promocodestat/${couponID}${intervals}?limit=${this.pagination.limit}&offset=${this.pagination.offset}`)
            .then((response) => {
                console.log(response)
                this.statistics = response[0]
                this.pagination.total = response[1]
            })
            .catch((response) => {})
    }
}

export default new promoController()
