import { observer } from 'mobx-react-lite'
import { Titles } from '../../layouts/Titles'
import { Row, Col, Button, Divider, Space } from 'antd'
import { useEffect } from 'react'
import i from '../../translations/i'
import { ErrorByPricingPlan } from '../../components/ErrorByPricingPlan'
import { storeLocal } from '../../helpers/stores'
import __schedule from './imports/__schedule'

export const Settings = observer(() => {

    useEffect(() => {
        __schedule.get()
    }, [])

    let navigation = [
        {
            label: i.t('nav:settings_project'),
            items: [
                {
                    label: i.t('settings:project.title'),
                    href: `/settings/project`,
                    visible: true,
                    plan: 0,
                },
                {
                    label: i.t('nav:nav'),
                    href: `/settings/navigation`,
                    visible: true,
                    plan: 0,
                },
                {
                    label: i.t('nav:integrations'),
                    href: `/settings/integrations`,
                    visible: true,
                    plan: 1,
                },
                {
                    label: i.t('nav:onBoarding'),
                    href: `/settings/onboarding`,
                    visible: true,
                    plan: 1,
                },
                {
                    label: i.t('nav:bonus_program'),
                    href: `/settings/bonus_program`,
                    visible: true,
                    plan: 0,
                },
                {
                    label: i.t('def:Referral system'),
                    href: `/settings/referral`,
                    visible: true,
                    plan: 3,
                },
                {
                    label: i.t('def:List of discounts'),
                    href: `/settings/discount`,
                    visible: true,
                    plan: 1,
                },
                {
                    label: i.t('def:Payment types'),
                    href: `/settings/payment_types`,
                    visible: true,
                    plan: 0,
                },
                {
                    label: i.t('def:Payment gateway list'),
                    href: `/settings/payment_gateway`,
                    visible: true,
                    plan: 0,
                },
            ],
        },
        {
            label: i.t('settings:main'),
            items: [
                {
                    label: i.t('settings:cities.title'),
                    href: `/settings/cities` ,
                    visible: true,
                    plan: 2,
                },
                {
                    label: i.t('settings:storage.title'),
                    href: `/settings/warehouses`,
                    visible: true,
                    plan: 2,
                },
                {
                    label: i.t('nav:places'),
                    href: `/settings/places`,
                    visible: true,
                    plan: 0,
                },
                {
                    label: i.t('def:managers.list'),
                    href: `/settings/managers`,
                    visible: true,
                    plan: 0,
                },
                {
                    label: i.t('settings:notifications.title'),
                    href: `/settings/notifications`,
                    visible: true,
                    plan: 0,
                },
            ]
        },
        {
            label: i.t('settings:catalog'),
            items: [
                {
                    label: i.t('def:importCatalog'),
                    href: `/settings/import`,
                    visible: true,
                    plan: 0,
                },
                {
                    label: i.t('settings:import.settings'),
                    href: `/settings/import/schedule`,
                    visible: true,
                    plan: 0,
                },
                {
                    label: i.t('settings:sbis.last'),
                    href: `/settings/import/stat`,
                    visible: true,
                    plan: 0,
                },
            ]
        },
        {
            label: i.t('settings:delivery.delivery'),
            items: [
                {
                    label: i.t('settings:order.title'),
                    href: `/settings/orders`,
                    visible: true,
                    plan: 0,
                },
                {
                    label: i.t('def:Roadmap'),
                    href: `/settings/roadmap`,
                    visible: true,
                    plan: 2,
                },
                {
                    label: i.t('settings:delivery.base.title'),
                    href: `/settings/delivery/base`,
                    visible: true,
                    plan: 0
                },
                {
                    label: i.t('settings:delivery.intervals.title'),
                    href: `/settings/delivery_intervals`,
                    visible: true,
                    plan: 0
                },
                {
                    label: i.t('settings:delivery.zones.title'),
                    href: `/settings/delivery/zones`,
                    visible: true,
                    plan: 0
                },
            ]
        }
    ]

    return(
        <>
            <Titles
                title={i.t('def:settings')}
                breadcrumb={[ { title: i.t('def:settings') } ]}
            />

            <Row gutter={[50, 30]}>
                {navigation.map(el => (
                    <Col span={24}>
                        <Row>
                            <Col span={24}>
                                <Divider orientation="left">{el.label}</Divider>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[15, 15]}>
                                    {el.items.map(item => item.visible && (
                                        <Col span={8}>
                                            <ErrorByPricingPlan
                                                plan={item.plan}
                                                content={
                                                    <Button
                                                        type="primary"
                                                        href={item.href}
                                                        disabled={storeLocal.get('pricing_plan') < item.plan}
                                                        block
                                                    >
                                                        {item.label}
                                                    </Button>
                                                }
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                ))}
            </Row>
        </>
    )
})