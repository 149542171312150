import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../helpers/request'
import i from '../../../translations/i'
import { notification } from 'antd'
import __multiple from '../../../helpers/multiple_actions/__multiple'
import layoutController from '../../../layouts/controllers/layout.controller'

class __order {

    list: any = []

    item: any = {
        id: 0,
        products: [],
        free_products: []
    }

    filter_list: any = {
        name: null,
        id: null,
        id_crm: null,
        vendor_code: null,
        category_id: null
    }

    editable: boolean = false

    select = {
        open: false,
        type: 'products'
    }

    usersModal = {
        open: false
    }

    products: any[] = []
    users: any[] = []

    pagination = {
        total: 0,
        page: 1,
        limit: 10,
        is_archive: false
    }

    statuses: any[] = []

    filterOrders = {
        open: false,
        filters: {
            id: null,
            status: [],
            client_email: '',
            client_phone: '',
            recipient_phone: ''
        }
    }

    holding_modal = {
        open: false,
        sum: 0
    }

    constructor() {
        makeAutoObservable(this)
    }

    setOrder() {
        this.editable = true
        this.item = {
            id: 0,
            products: [],
            free_products: [],
            status: 1,
            payment_status: 1,
            payment_type: 1,
            payment_type_id: null,
            delivery: 'default',
            delivery_type: 1,
            user_id: null,
            recipient: 0,
            recipient_name: '',
            recipient_phone: '',
            system_info: 'Cloud web',
            cutlery: 0,
            is_archive: false
        }
    }

    getList() {
        let url = `/order?limit=${this.pagination.limit}&page=${this.pagination.page}`
        if (this.pagination.is_archive) url += `&is_archive=${+this.pagination.is_archive}`

        if (this.filterOrders.filters.id) url += `&filter_id=${this.filterOrders.filters.id}`
        if (this.filterOrders.filters.client_phone) url += `&filter_client_phone=${this.filterOrders.filters.client_phone}`
        if (this.filterOrders.filters.client_email) url += `&filter_client_email=${this.filterOrders.filters.client_email}`
        if (this.filterOrders.filters.recipient_phone) url += `&filter_recipient_phone=${this.filterOrders.filters.recipient_phone}`
        if (this.filterOrders.filters.status.length) {
            this.filterOrders.filters.status.map(el => url += `&filter_status[]=${el}`)
        }

        console.log(url)
        openApi('get', url)
            .then(result => {
                console.log(result)
                this.list = result.data
                this.pagination.total = result.total
                this.filterOrders.open = false
            })
          .catch(error => console.log(error.response))
    }

    setPage(page: number) {
        this.pagination.page = page
        this.getList()
    }

    getOrder(id: number | string) {
        openApi('get', `/order/${id}?with[]=products&with[]=free_products&with[]=promo_code_user&with[]=history&with[]=warehouse&with[]=discounts&with[]=rejection_reason_object&with[]=payment_type_object`)
            .then(result => {
                console.log(result)
                this.item = result.data

                if (this.item.viewed === 0) {
                    this.view(id)
                }
            })
          .catch(err => console.log(err.response))
    }

    setEditable() {
        this.editable = !this.editable
    }

    save(changeEditable: boolean = true) {
        const data = new FormData()

        let productsCount = 0;
        ['products', 'free_products'].map((type: string) => {
            this.item[type].map((el: any) => {
                data.append(`products[${productsCount}][id]`, `${el.id}`)
                data.append(`products[${productsCount}][product_id]`, `${el.product_id}`)
                data.append(`products[${productsCount}][quantity]`, `${el.count}`)
                data.append(`products[${productsCount}][price]`, `${el.price}`)
                data.append(`products[${productsCount}][free]`, `${+el.free}`)
                productsCount++
            })
        })

        console.log(productsCount, this.item.user_id)

        if (productsCount === 0 || this.item.user_id < 1) {
            return notification.error({
                message: i.t('def:errors.error'),
                description: i.t('def:errors.data')
            })
        }

        if (this.item.recipient === 0 || !this.item.recipient) {
            this.item.recipient_name = ''
            this.item.recipient_phone = ''
        }

        if (!this.item.cutlery) {
            this.item.cutlery = 0
        }

        if (!this.item.comment || this.item.comment === 'null') {
            this.item.comment = ''
        }

        data.append('status', this.item.status)
        data.append('payment_status', this.item.payment_status)
        if(this.item.payment_type) data.append('payment_type', this.item.payment_type)
        if(this.item.payment_type_id) data.append('payment_type_id', this.item.payment_type_id)
        data.append('recipient', this.item.recipient ?? 0)
        data.append('recipient_name', this.item.recipient_name)
        data.append('recipient_phone', this.item.recipient_phone)
        data.append('comment', this.item.comment)
        data.append('cutlery', this.item.cutlery)
        data.append('user_id', this.item.user_id)
        data.append('firstname', this.item.firstname)
        data.append('lastname', this.item.lastname)
        data.append('phone', this.item.phone)
        data.append('email', this.item.email)
        data.append('delivery_type', this.item.delivery_type)
        data.append('delivery', this.item.delivery)
        data.append('delivery_date', this.item.delivery_date)
        data.append('address', this.item.address)
        data.append('address_full', this.item.address_full)
        //data.append('', this.item.)

        if(this.item.cashback >= 0)
            data.append('cashback', this.item.cashback)

        openApi(`post`, `/order/${this.item.id}`, data, { key: 'System-Info', value: this.item.system_info })
            .then(result => {
                if (this.item.id > 0) {
                    if (changeEditable) this.setEditable()
                    this.getOrder(this.item.id)
                    notification.success({
                        message: i.t('def:success.success'),
                        description: i.t('def:success.order')
                    })
                } else {
                    if (result.data.id > 0) {
                        window.location.href = `/shop/orders/${result.data.id}`
                    }
                }
                console.log(result)
            })
            .catch(error => {
                console.log(error.response)
            })

    }

    deleteProduct(position_id: number, type: string) {
        this.item[type] = this.item[type].filter((el: any) => el.id !== position_id)
    }

    setQuantity(position_id: number, quantity: number, weight_goods: boolean, step: number) {
        let index = this.item.products.findIndex((el: any) => el.id === position_id)
        if (index > -1) {
            if (weight_goods || step > 1) {
                quantity = quantity / step
            }

            this.item.products[index].count = quantity
        } else {
            index = this.item.free_products.findIndex((el: any) => el.id === position_id)

            if (index > -1) {
                if (weight_goods || step > 1) {
                    quantity = quantity / step
                }
                this.item.free_products[index].count = quantity
            }
        }
    }

    setSelect(type: string = '') {
        this.select.open = !this.select.open
        if (this.select.open) {
            this.pagination.page = 1
            this.select.type = type
            this.getProducts()
        }
    }

    getProducts() {
        let uri = `/products/select?with[]=image&limit=${this.pagination.limit}&page=${this.pagination.page}`
        if(this.filter_list.id) uri += `&filter[product_id][]=${this.filter_list.id}`
        if(this.filter_list.name) uri += `&filter[name]=${this.filter_list.name}`
        if(this.filter_list.id_crm) uri += `&filter[id_crm]=${this.filter_list.id_crm}`
        if(this.filter_list.vendor_code) uri += `&filter[vendor_code]=${this.filter_list.vendor_code}`
        if(this.filter_list.category_id) uri += `&filter[category_id]=${this.filter_list.category_id}`

        openApi('get', uri)
            .then(result => {
                console.log(result)
                this.products = result.data
                this.pagination.limit = result.limit
                this.pagination.page = Number(result.page)
                this.pagination.total = result.total
            })
            .catch(error => {

            })
    }

    setUsers() {
        this.usersModal.open = !this.usersModal.open
        if (this.usersModal.open) {
            this.pagination.page = 1
            this.getUsers()
        }
    }

    getUsers() {
        openApi('get', `/users?limit=${this.pagination.limit}&offset=${this.pagination.page * this.pagination.limit}`)
            .then(result => {
                result = result.data
                this.pagination.total = result.count
                this.users = result.list
            })
    }

    setProductsPage(page: number) {
        this.pagination.page = page
        this.getProducts()
    }

    setFilterProducts(name: string, value: any) {
        this.filter_list[name] = value
        this.pagination.page = 1
        this.getProducts()
    }

    setUsersPage(page: number) {
        this.pagination.page = page
        this.getUsers()
    }

    addProduct(product: any) {
        let props: any[] = []

        if (product.props && Array.isArray(product.props)) {
            product.props.map((el: any) => {
                props.push({
                    category: el.category,
                    value: {
                        label: el.label,
                        value: el.value
                    }
                })
            })
        }

        this.item[this.select.type].push({
            id: Date.now(),
            product: product,
            count: 1,
            product_id: product.id,
            free: this.select.type === 'free_products' ? 1 : 0,
            price: this.select.type === 'free_products' ? 0 : product.price,
            props: props
        })

        this.select.open = false
    }

    getStatuses() {
        openApi(`get`, `/order_status`)
            .then(result => {
                console.log(result.data)
                this.statuses = result.data
            })
    }

    toggleRecipient() {
        this.item.recipient = this.item.recipient === 1 ? 0 : 1
    }

    toggleUser() {
        if (this.item.user_id < 1) {
            this.setUsers()
        } else {
            this.item.user_id = 0
            this.item.firstname = ''
            this.item.lastname = ''
            this.item.phone = ''
            this.item.email = ''
        }
    }

    addUser(user: any) {
        this.item.user_id = user.id
        this.item.firstname = user.firstname ?? ''
        this.item.lastname = user.lastname ?? ''
        this.item.phone = user.phone ?? ''
        this.item.email = user.email ?? ''
        this.setUsers()
    }

    getDeliveryNameByType(type: string) {
        let r = ''
        if (type === 'sdek') r = i.t('order:sdek')
        else if (type === 'boxbery') r = i.t('order:boxbery')
        else if (type === 'pochta') r = i.t('order:rf')
        else if (type === 'novopochta') r = i.t('order:novopochta')
        else if (type === 'ukrpochta') r = i.t('order:ukrpochta')

        return r
    }

    filterClear() {
        this.filterOrders = {
            open: false,
            filters: {
                id: null,
                status: [],
                client_email: '',
                client_phone: '',
                recipient_phone: ''
            }
        }
        this.getList()
    }

    toArchive(orderID = 0) {
        const data = new FormData()

        if (orderID > 0) {
            data.append('elements[]', `${orderID}`)
        } else {
            __multiple.selected.map(el => data.append('elements[]', `${el}`))
        }

        openApi('post', '/shop/orders/archive/to', data)
            .then(result => {
                if (orderID > 0) {
                    this.getOrder(orderID)
                } else {
                    __multiple.clear()
                    this.getList()
                }
            })
    }

    fromArchive(orderID = 0) {
        const data = new FormData()

        if (orderID > 0) {
            data.append('elements[]', `${orderID}`)
        } else {
            __multiple.selected.map(el => data.append('elements[]', `${el}`))
        }

        openApi('post', '/shop/orders/archive/from', data)
            .then(result => {
                if (orderID > 0) {
                    this.getOrder(orderID)
                } else {
                    __multiple.clear()
                    this.getList()
                }
            })
    }

    viewed() {
        const data = new FormData()

        if (__multiple.selected.length > 0)
            __multiple.selected.map(el => data.append('elements[]', `${el}`))

        openApi('post', '/shop/orders/viewed', data)
            .then(() => {
                __multiple.clear()
                this.getList()
                layoutController.getMarkers()
            })
    }

    getActions() {
        if (this.pagination.is_archive) {
            return [
                { label: i.t('def:fromArchive'), value: 'fromArchive', action: () => this.fromArchive() },
                { label: i.t('def:reading'), value: 'viewed', action: () => this.viewed() }
            ]
        } else {
            return [
                { label: i.t('def:toArchive'), value: 'toArchive', action: () => this.toArchive() },
                { label: i.t('def:reading'), value: 'viewed', action: () => this.viewed() }
            ]
        }
    }

    view(id: number | string) {
        const data = new FormData()

        data.append('elements[]', `${id}`)

        openApi('post', '/shop/orders/viewed', data)
            .then(() => {

            })
    }

    un_holding(orderID: any) {
        if(window.confirm(i.t('def:un_holding_confirm')))
            openApi('post', `/order/un_holding/${orderID}`)
              .then(result => {
                  console.log(result)
                  this.getOrder(orderID)
              })
              .catch(error => console.log(error.response))
    }

    closeModalHolding() {
        this.holding_modal.sum = 0
        this.holding_modal.open = false
    }

    holding(orderID: any) {
        this.holding_modal.sum = this.item.sum
        this.holding_modal.open = true
    }

    saveHolding(orderID: any) {
        this.holding_modal.open = false
        const data = new FormData()
        data.append('sum', `${this.holding_modal.sum}`)

        openApi('post', `/order/holding/${orderID}`, data)
          .then(result => {
              this.getOrder(orderID)
          })
          .catch(error => console.log(error.response))
    }
}

export default new __order()