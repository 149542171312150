import { observer } from 'mobx-react-lite'
import i from '../../translations/i'
import { Titles } from '../../layouts/Titles'
import { Button, Col, Image, Input, Row, Select, Space, Switch, Table, Tooltip, TreeSelect, Upload } from "antd";
import { DeleteOutlined, PlusOutlined, SettingOutlined, UnorderedListOutlined } from "@ant-design/icons";
import React, { FormEvent, useEffect } from "react";
import controller from "./news.categories.controller";
import { useParams } from "react-router-dom";
import { runInAction } from "mobx";
import { SketchPicker } from "react-color";
import { deleteFile } from "../../helpers/files.controller";
import categories_controller from "./news.categories.controller";

export const NewsCategories = observer(() => {

  let { id = null }: any = useParams()

  useEffect(() => {
    return () => {
      if(id) controller.parent_id = id
      controller.getList()
    }
  }, [])

  const columns = [
    {
      title: "ID",
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: i.t('def:image.image'),
      dataIndex: 'image',
      key: 'image',
      render: (image: any) => image ? <Image width={'100px'} src={`${image.base}${image.uri}`} /> : <></>,
    },
    {
      title: i.t('def:name'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: i.t('def:actions.actions'),
      key: 'action',
      render: (record: any) => (
        <Space>

          {record.subcategories
            ? <>
              <Button
                type="dashed" shape="circle" icon={<UnorderedListOutlined />}
                href={`/news_categories/${record.id}`} />
            </>
            : <></>
          }


          <Tooltip title={i.t('def:actions.edit')}>
            <Button type="dashed" shape="circle" href={`/news_categories/edit/${record.id}`} icon={<SettingOutlined />} />
          </Tooltip>

          <Button
            type="dashed"
            shape="circle"
            danger
            icon={<DeleteOutlined />}
            onClick={() => controller.delete(record.id)}
          />
        </Space>
      ),
    },
  ]

  return(
    <>
      <Titles
        title={i.t('def:News categories')}
        buttons={[
          { title: i.t('def:actions.create'), type: 'primary', href: `/news_categories/edit/0`, plan: 1 }
        ]}
        breadcrumb={[
          {title: i.t('def:News categories')},
        ]}
      />

      <Table
        columns={columns}
        dataSource={controller.list}
        pagination={false}
      />
    </>
  )
})

export const NewsCategory = observer(() => {

  let { id }: any = useParams()

  useEffect(() => {
    return () => {
      controller.getList(null, true)
      controller.getOne(id)
    }
  }, [])

  const buttons = [
    { title: i.t('def:actions.backList'), href: `/news_categories`, type: 'dashed' },
    { title: i.t('def:actions.save'), htmlType: `submit`, type: 'primary' }
  ]

  return(
    <>
      <form onSubmit={(e: FormEvent) => controller.save(e)}>
        <Titles
          title={i.t('def:News type')}
          buttons={buttons}
        />

        <Row gutter={[20, 20]}>
          <Col xs={24} className="switch-block">
            <div className="element">
              <div></div>
              <Switch
                checked={controller.item.enabled}
                size="small"
                onChange={e => runInAction(() => controller.item.enabled = e)}
              />
              <div>
                <span>{i.t('settings:cities.form.enabled')}</span>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <label>{i.t('settings:cities.form.name')}</label>
            <Input
              name="title"
              placeholder={i.t('settings:cities.form.name')}
              value={controller.item.name}
              onChange={(e) => runInAction(() => controller.item.name = e.target.value)}
            />
          </Col>
          <Col xs={24} md={12}>
            <label>{i.t('def:Name encode')}</label>
            <Input
              name="title"
              placeholder={i.t('def:Name encode')}
              value={controller.item.name_encode}
              onChange={(e) => runInAction(() => controller.item.name_encode = e.target.value)}
            />
          </Col>
          <Col xs={24} md={12}>
            <label>{i.t('def:View type')}</label>
            <Select
              value={controller.item.view_type}
              options={[
                {value: 1, label: i.t('settings:project.selectOptions.list')},
                {value: 2, label: i.t('settings:project.selectOptions.tile')},
                {value: 5, label: i.t('settings:project.selectOptions.horizontal')},
                {value: 6, label: i.t('settings:project.selectOptions.horizontalTight')},
                {value: 9, label: i.t('settings:project.selectOptions.tile2')},
              ]}
              style={{width: `100%`}}
              onChange={(e) => runInAction(() => controller.item.view_type = e)}
            />
          </Col>
          <Col xs={24} md={12}>
            <label>{i.t('def:Select parent category')}</label>

            <TreeSelect
              value={controller.item.parent_id}
              treeData={[
                { label: '', value: 0 },
                ...controller.list
              ]}
              style={{width: `100%`}}
              onChange={(e) => {
                console.log(e)
                controller.item.parent_id = e && e > 0 ? e : null;
              }}
            />
          </Col>
          <Col span={24}>
            {controller.item.image
              ? <Row gutter={[10, 10]}>
                <Col xs={24}>
                  <img src={controller.item.image.base + controller.item.image.uri} alt='' style={{maxWidth: '250px'}}/>
                </Col>
                <Col xs={24}>
                  <Button
                    onClick={() => {
                      deleteFile(controller.item.image.id)
                      controller.item.image = null}
                    }
                  >
                    {i.t('def:deletePicture')}
                  </Button>
                </Col>
              </Row>
              : <></>
            }


            <Upload
              beforeUpload={(info) => {
                controller.upload(info)
                return false
              }}
              listType="picture-card"
              className="photo-uploader"
              maxCount={1}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>{i.t('def:image.upload')}</div>
              </div>
            </Upload>
          </Col>
        </Row>
      </form>
    </>
  )
})