import { makeAutoObservable } from 'mobx'
import { openApi } from "../../../helpers/request";
import { FormEvent } from "react";
import i from "../../../translations/i";

class PaymentTypeController {

  list: any[] = []
  item: any = {
    id: null,
    enabled: true,
    label: null,
    online: false,
    payment_gateway_id: null,
    button_label: null,
    requires_change: false,
    description: null,
    type: 'card_online'
  }

  settingDelivery: any[] = []

  data: any = {
    zones: [],
    warehouses: [],
    places: []
  }

  dataSelected: any = {
    zones: [],
    warehouses: [],
    places: []
  }

  constructor() {
    makeAutoObservable(this)
  }

  save(event: FormEvent) {
    event.preventDefault()

    const data = new FormData()
    data.append('label', this.item.label)
    data.append('online', `${+ this.item.online}`)
    if(this.item.payment_gateway_id) data.append('payment_gateway_id', `${this.item.payment_gateway_id}`)
    data.append('button_label', this.item.button_label)
    data.append('tax_system_code', this.item.tax_system_code)
    data.append('requires_change', `${+ this.item.requires_change}`)
    if(this.item.description) data.append('description', `${this.item.description}`)
    data.append('type', this.item.type)
    data.append('enabled', `${+ this.item.enabled}`)

    if(this.dataSelected.zones.length > 0) {
      this.dataSelected.zones.map((el: string | number, index: number) => data.append(`zones[${index}]`, `${el}`))
    }

    if(this.dataSelected.places.length > 0) {
      this.dataSelected.places.map((el: string | number, index: number) => data.append(`places[${index}]`, `${el}`))
    }

    if(this.dataSelected.warehouses.length > 0) {
      this.dataSelected.warehouses.map((el: string | number, index: number) => data.append(`warehouses[${index}]`, `${el}`))
    }

    if(this.settingDelivery.length > 0) {
      this.settingDelivery.map((el: any, index: number) => {
        data.append(`delivery[${index}][type]`, `${el.type}`)
        if(el.company)
          data.append(`delivery[${index}][company]`, el.company)
      })
    }

    let uri = `/payment_types/`

    if(this.item.id && this.item.id > 0)
      uri += this.item.id

    openApi('post', uri, data)
      .then((result: any) => {
        console.log(result)
        if(this.item.id && this.item.id > 0)
          window.location.reload()
        else window.location.href = `/settings/payment_types`
      })
      .catch(error => console.log(error.response))
  }

  getList() {
    openApi('get', `/payment_types`)
      .then((result: any) => {
        console.log(result)
        this.list = result.data
      })
      .catch(error => console.log(error.response))
  }

  getOne(id: any) {
    if(id && id > 0) {
      openApi('get', `/payment_types/${id}`)
        .then((result: any) => {
          console.log(result)
          this.item = result.data
          this.dataSelected = result.data.selected
          this.settingDelivery = result.data.delivery
        })
        .catch(error => console.log(error.response))
    }

    openApi('get', `/data_data`)
      .then((result: any) => {
        console.log(result)
        this.data = result.data
      })
      .catch(error => console.log(error.response))
  }

  destroy(id: any) {
    if(window.confirm(i.t('def:confirms.delete')))
      openApi('delete', `/payment_types/${id}`)
        .then((result: any) => {
          window.location.reload()
        })
        .catch(error => console.log(error.response))
  }

  addSettingDelivery() {
    this.settingDelivery.push({
      type: 'delivery',
      company: null
    })
  }

  editSettingDelivery(index: number, type: string, e: number) {
    this.settingDelivery[index][type] = e
  }

  setSorting(id: number, action: string) {

    openApi('post', `/payment_types/sorting/${id}/${action}`)
      .then((result: any) => {
        window.location.reload()
      })
      .catch(error => console.log(error.response))
  }

}

export default new PaymentTypeController()