import { observer } from "mobx-react-lite";
import PaymentTypeController from "./payment.type.controller";
import PaymentGatewayController from "./payment.gateway.controller";
import React, { FormEvent, useEffect } from "react";
import i from "../../../translations/i";
import { Titles } from "../../../layouts/Titles";
import {
  Badge,
  Button,
  Col,
  Divider,
  Input,
  Select,
  Row,
  Segmented,
  Space,
  Switch,
  Table,
  Tooltip,
  Upload
} from "antd";
import { useParams } from "react-router-dom";
import {EditOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined} from '@ant-design/icons'
import { runInAction } from "mobx";
import { SwitchBlock } from "../../../components/SwitchBlock";

export const PaymentTypeElement = observer(() => {

  let { id }: any = useParams()

  useEffect(() => {
    PaymentTypeController.getOne(id)
    PaymentGatewayController.getList()
  }, [])

  return(
    <>
      <form onSubmit={(e: FormEvent) => PaymentTypeController.save(e)}>
        <Titles
          title={i.t('def:Payment type')}
          buttons={[
            { title: i.t('def:actions.backList'), type: 'dashed', href: `/settings/payment_types` },
            { title: i.t('def:actions.save'), type: 'primary', htmlType: 'submit' }
          ]}
          breadcrumb={[
            {title: i.t('def:settings'), href: '/settings'},
            { title: i.t('def:Payment types'), href: '/settings/payment_types' },
            { title: i.t('def:Payment type') },
          ]}
        />

        <Divider />

        <Row gutter={[20, 20]}>
          <Col xs={24} className="switch-block">
            <div className="element" style={{margin: '0 0 10px'}}>
              <div></div>
              <Switch
                checked={PaymentTypeController.item.enabled}
                onChange={e => PaymentTypeController.item.enabled = e}
                size="small"
              />
              <div>
                <span>{i.t('settings:cities.form.enabled')}</span>
              </div>
            </div>
          </Col>
          <Col span={12}>
            <label className="formLabel">{i.t('def:name')}</label>
            <Input
              name="title"
              placeholder={i.t('def:name')}
              value={PaymentTypeController.item.label}
              onChange={(e) => PaymentTypeController.item.label = e.target.value}
              required
            />
          </Col>
          <Col span={12}>
            <label className="formLabel">{i.t('def:description')}</label>
            <Input
              name="title"
              placeholder={i.t('def:description')}
              value={PaymentTypeController.item.description}
              onChange={(e) => PaymentTypeController.item.description = e.target.value}
            />
          </Col>
          <Col span={12}>
            <label className="formLabel">{i.t('def:Button label')}</label>
            <Input
              placeholder={i.t('def:Button label')}
              value={PaymentTypeController.item.button_label}
              onChange={(e) => PaymentTypeController.item.button_label = e.target.value}
              required
            />
          </Col>
          <Divider />
          <Col xs={24}>
            <SwitchBlock array={[
              { label: i.t('def:Online payment'), click: (e: any) => PaymentTypeController.item.online = e, value: PaymentTypeController.item.online },
              { label: i.t('def:Requires change'), click: (e: any) => PaymentTypeController.item.requires_change = e, value: PaymentTypeController.item.requires_change },
            ]} />
          </Col>

          {PaymentTypeController.item.online
            ? <>
              <Divider />
              <Col span={12}>
                <label className="formLabel">{i.t('def:Payment gateway')}</label>
                <Select
                  value={PaymentTypeController.item.payment_gateway_id}
                  options={[ { label: "", value: null },
                    ...PaymentGatewayController.list.map((el: any) => ({
                      label: el.label,
                      value: el.id
                    }))]}
                  onChange={(e: any) => PaymentTypeController.item.payment_gateway_id = e}
                  style={{width: `100%`}}
                />
              </Col>
              <Col span={12}>
                <label className="formLabel">{i.t('def:Payment type')}</label>
                <Select
                  value={PaymentTypeController.item.type}
                  options={[
                    { label: i.t('def:Yookassa card_online'), value: 'card_online' },
                    { label: i.t('def:Yookassa sberpay'), value: 'sberpay' },
                    { label: i.t('def:Yookassa sbp'), value: 'sbp' },
                    { label: i.t('def:Yookassa mirpay'), value: 'mirpay' },
                    { label: i.t('def:Yookassa tinkoff_pay'), value: 'tinkoff_pay' },
                  ]}
                  onChange={(e: any) => PaymentTypeController.item.type = e}
                  style={{width: `100%`}}
                />
              </Col>
            </>
            : <></>
          }


          <Divider />
          <Col span={24}>
            <Row gutter={[20, 20]}>
              <Col span={24}>
                <label>{i.t('def:managers.deliveryZones')}</label>
                <Select
                  mode="multiple"
                  size="middle"
                  placeholder={i.t('def:managers.selectDeliveryZones')}
                  onChange={(e) => runInAction(() => PaymentTypeController.dataSelected.zones = e)}
                  value={PaymentTypeController.dataSelected.zones}
                  style={{ width: '100%' }}
                  options={PaymentTypeController.data.zones.map((el: any) => ({
                    value: el.id,
                    label: el.name
                  }))}
                />
              </Col>
              <Col span={24}>
                <label>{i.t('def:managers.warehouses')}</label>
                <Select
                  mode="multiple"
                  size="middle"
                  placeholder={i.t('def:managers.selectWarehouses')}
                  onChange={(e) => runInAction(() => PaymentTypeController.dataSelected.warehouses = e)}
                  value={PaymentTypeController.dataSelected.warehouses}
                  style={{ width: '100%' }}
                  options={PaymentTypeController.data.warehouses.map((el: any) => ({
                    value: el.id,
                    label: el.name
                  }))}
                />
              </Col>
              <Col span={24}>
                <label>{i.t('def:managers.points')}</label>
                <Select
                  mode="multiple"
                  size="middle"
                  placeholder={i.t('def:managers.selectPoints')}
                  onChange={(e) => runInAction(() => PaymentTypeController.dataSelected.places = e)}
                  value={PaymentTypeController.dataSelected.places}
                  style={{ width: '100%' }}
                  options={PaymentTypeController.data.places.map((el: any) => ({
                    value: el.id,
                    label: el.name
                  }))}
                />
              </Col>
              <Divider />
              <Col span={24}>
                <Row gutter={[20, 20]}>
                  <Col span={24}>
                    <label className="formLabel">Ограничения</label>
                  </Col>
                  <Col span={24}>
                    <Button onClick={() => PaymentTypeController.addSettingDelivery()}>Добавить ограничение</Button>
                  </Col>
                  {PaymentTypeController.settingDelivery.map((el: any, index: number) => (
                    <Col span={24}>
                      <Row gutter={[20, 20]} justify="space-between" align="bottom">
                        <Col span={10}>
                          <label>Укажите способ получения</label>
                          <Select
                            size="middle"
                            placeholder={i.t('def:Select deliveries')}
                            onChange={(e) => runInAction(() => PaymentTypeController.editSettingDelivery(index, 'type', e))}
                            value={el.type}
                            style={{ width: '100%' }}
                            options={[
                              { label: 'Доставка', value: 'delivery' },
                              { label: 'Самовывоз', value: 'pickup' },
                            ]}
                          />
                        </Col>
                        <Col span={10}>
                          <label>Укажите транспортную компанию</label>
                          <Select
                            size="middle"
                            placeholder={i.t('def:Select deliveries')}
                            onChange={(e) => runInAction(() => PaymentTypeController.editSettingDelivery(index, 'company', e))}
                            value={el.company}
                            style={{ width: '100%' }}
                            options={[
                              { label: el.type === 'delivery' ? 'Собственная доставка' : 'Собственный самовывоз', value: null },
                              { label: 'СДЕК', value: 'cdek' },
                              { label: 'Боксбери', value: 'boxberryy' },
                              { label: 'Почта РФ', value: 'pochta' },
                            ]}
                          />
                        </Col>
                        <Col span={4}>
                          <Button
                            icon={<DeleteOutlined />}
                            shape="circle"
                            type="dashed"
                            onClick={(() => PaymentTypeController.settingDelivery.splice(index, 1))}
                            danger
                          />
                        </Col>
                      </Row>
                    </Col>
                  ))}

                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </>
  )
})

export const PaymentTypeList = observer(() => {

  useEffect(() => {
    PaymentTypeController.getList()
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '',
      dataIndex: 'enabled',
      key: 'enabled',
      render: (el: boolean) => <Badge status={el ? "success" : "error"} />
    },
    {
      title: i.t('def:Label'),
      dataIndex: 'label',
      key: 'label'
    },
    {
      title: i.t('def:description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: i.t('orders:actions'),
      key: 'action',
      render: (item: any) => (
        <>
          <Space>

            <Button
              type="dashed"
              onClick={() => PaymentTypeController.setSorting(item.id, 'up')}
              icon={<ArrowUpOutlined />}
              shape="circle"
            />
            <Button
              type="dashed"
              onClick={() => PaymentTypeController.setSorting(item.id, 'down')}
              icon={<ArrowDownOutlined />}
              shape="circle"
            />

            <Button
              type="dashed"
              href={`/settings/payment_types/${item.id}`}
              icon={<EditOutlined />}
              shape="circle"
            />

            <Button
              type="dashed"
              onClick={() => PaymentTypeController.destroy(item.id)}
              icon={<DeleteOutlined />}
              shape="circle"
              danger
            />
          </Space>
        </>
      )
    }
  ]

  return(
    <>
      <Titles
        title={i.t('def:Payment types')}
        buttons={[
          { title: i.t('def:actions.create'), type: 'primary', href: `/settings/payment_types/0` }
        ]}
        breadcrumb={[
          {title: i.t('def:settings'), href: '/settings'},
          { title: i.t('def:Payment types') },
        ]}
      />

      <Table
        rowKey="id"
        columns={columns}
        dataSource={PaymentTypeController.list}
        pagination={false}
      />

    </>
  )
})