import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import React, { FormEvent, useEffect } from "react";
import controller from "../../news.controller";
import i from "../../../../translations/i";
import { Button, Col, Divider, Dropdown, Input, Row, TreeSelect, Select, Space, DatePicker } from "antd";
import { runInAction } from "mobx";
import { SwitchBlock } from "../../../../components/SwitchBlock";
import { WarehousesController } from "../../../settings/warehouses/warehouses.controller";
import types_controller from '../../news.types.controller'
import categories_controller from '../../news.categories.controller'
import dayjs from "dayjs";
import ReactQuill from "react-quill";


let w_c = new WarehousesController();

export const NewsModuleMain = observer(() => {


  useEffect(() => {
    w_c.get(1000)
    types_controller.getList(1000)
    categories_controller.getList(1000, true)
  }, [])



  return(
    <>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <SwitchBlock array={[
            { label: i.t('def:active'), click: (e: any) => controller.item.enabled = e, value: controller.item.enabled },
            { label: i.t('def:Send notifications'), click: (e: any) => controller.item.send_notifications = e, value: controller.item.send_notifications },
          ]} />
        </Col>
        <Col xs={{span: 24}} md={{span: 12}}>
          <label className="formLabel">{i.t('def:name')}</label>
          <Input
            value={controller.item.name}
            onChange={ (e) => runInAction(() => controller.item.name = e.target.value) }
            required
          />
        </Col>
        <Col xs={{span: 24}} md={{span: 12}}>
          <label className="formLabel">{i.t('def:seo.name_encode')}</label>
          <Input
            value={controller.item.name_encode}
            onChange={ (e) => runInAction(() => controller.item.name_encode = e.target.value) }
          />
        </Col>
        <Col xs={24} md={24}>
          <label>{i.t('def:Select categories')}</label>
          <TreeSelect
            value={controller.item.categories.map((el: any) => el.category_id)}
            treeData={categories_controller.list}
            multiple
            style={{width: `100%`}}
            onChange={(e) => runInAction(() => {
              let arr: any[] = []
              e.map((el: number) => arr.push({
                category_id: el
              }))
              controller.item.categories = arr
            })}
          />
        </Col>
        <Col xs={24} md={24}>
          <label>{i.t('def:Select types')}</label>
          <Select
            value={controller.item.types.map((el: any) => el.type_id)}
            options={types_controller.list.map((el: any) => ({
              label: el.name,
              value: el.id
            }))}
            mode="multiple"
            style={{width: `100%`}}
            onChange={(e) => runInAction(() => {
              let arr: any[] = []
              e.map((el: number) => arr.push({
                type_id: el
              }))
              controller.item.types = arr
            })}
          />
        </Col>
        <Col span={24}>
          <Divider />
        </Col>
        <Col xs={24} md={12}>
          <label className="formLabel">{i.t('def:Publication date')}</label>
          <DatePicker
            format="DD.MM.YYYY"
            value={controller.item.publication_date ? dayjs(controller.item.publication_date) : null}
            placeholder={i.t('def:Publication date')}
            onChange={e => {
              controller.item.publication_date = e ? e.format() : null
            }}
            minDate={dayjs()}
            style={{ width: `100%` }}
          />
        </Col>
        <Col xs={24} md={12}>
          <label className="formLabel">{i.t('def:Date from')}</label>
          <DatePicker
            format="DD.MM.YYYY"
            value={controller.item.date_from ? dayjs(controller.item.date_from) : null}
            placeholder={i.t('def:Date from')}
            onChange={e => {
              console.log(e)
              controller.item.date_from = e ? e.format() : null
            }}
            minDate={dayjs()}
            style={{ width: `100%` }}
          />
        </Col>
        <Col xs={24} md={12}>
          <label className="formLabel">{i.t('def:Date to')}</label>
          <DatePicker
            format="DD.MM.YYYY"
            value={controller.item.date_to ? dayjs(controller.item.date_to) : null}
            placeholder={i.t('def:Date to')}
            onChange={e => {
              controller.item.date_to = e ? e.format() : null
            }}
            minDate={dayjs()}
            style={{ width: `100%` }}
          />
        </Col>
        <Col span={24}>
          <Divider />
        </Col>
        <Col xs={24} md={12}>
          <label>{i.t('def:View type')}</label>
          <Select
            value={controller.item.view_type}
            options={[
              { value: 'default', label: i.t('def:viewTypes.default') },
              { value: 'full', label: i.t('def:viewTypes.full') },
              { value: 'line', label: i.t('def:viewTypes.line') },
            ]}
            style={{width: `100%`}}
            onChange={(e) => runInAction(() => controller.item.view_type = e)}
          />
        </Col>
        <Col xs={24} md={24}>
          <label>{i.t('def:Select warehouse')}</label>
          <Select
            value={controller.item.warehouses.map((el: any) => el.warehouse_id)}
            options={w_c.list.map((el: any) => ({
              label: el.name,
              value: el.id
            }))}
            mode="multiple"
            style={{width: `100%`}}
            onChange={(e) => runInAction(() => {
              let arr: any[] = []
              e.map((el: number) => arr.push({
                warehouse_id: el
              }))
              controller.item.warehouses = arr
            })}
          />
        </Col>
        <Col xs={{span: 24}} md={{span: 24}}>
          <label className="formLabel">{i.t('def:internal_url')}</label>
          <Input
            value={controller.item.internal_url}
            onChange={ (e) => runInAction(() => controller.item.internal_url = e.target.value) }
          />
        </Col>
        <Col span={24}>
          <Divider />
        </Col>


        <Col xs={{span: 24}} md={{span: 24}}>
          <label className="formLabel">{i.t('def:seo.title')}</label>
          <Input
            value={controller.item.seo_title}
            onChange={ (e) => runInAction(() => controller.item.seo_title = e.target.value) }
          />
        </Col>
        <Col xs={{span: 24}} md={{span: 24}}>
          <label className="formLabel">{i.t('def:seo.description')}</label>
          <Input.TextArea
            value={controller.item.seo_description}
            onChange={ (e) => runInAction(() => controller.item.seo_description = e.target.value) }
          />
        </Col>
        <Col xs={{span: 24}} md={{span: 24}}>
          <label className="formLabel">{i.t('def:seo.keywords')}</label>
          <Input.TextArea
            value={controller.item.seo_keywords}
            onChange={ (e) => runInAction(() => controller.item.seo_keywords = e.target.value) }
          />
        </Col>

      </Row>

    </>
  )
})