import { makeAutoObservable } from 'mobx'
import { openApi } from '../../../helpers/request'
import { FormEvent } from 'react'
import __select from '../../../helpers/select_entity/__select.controller'
import { notification } from 'antd'
import i from '../../../translations/i'
import { __cpl } from "../../catalog/custom_product_lists/__cpl";

class __navigation {

    list: any[] = []
    element: any = {
        id: 0,
        enabled: true,
        label: '',
        icon: '',
        warehouse_id: null
    }
    type: any = 'tab_bar'
    warehouse = 0

    constructor() {
        makeAutoObservable(this)
    }

    setIcon(icon_name: string) {
        this.element.icon = icon_name
    }

    setType(type: any) {
        this.type = type
        this.getList()
    }

    getList() {
        let uri = `/navigation?type=${this.type}`
        if(this.warehouse) uri += `&warehouse_id=${this.warehouse}`
        openApi('get', uri)
            .then(result => {
                console.log(result)
                this.list = result.data
            })
    }

    getOne(id: any, type: string) {
        this.type = type
        if(id > 0) {
            openApi('get', `/navigation/${id}`)
                .then(result => {
                    console.log(result)
                    this.element = result.data

                    __select.setRefer(result.data.refer)
                })
              .catch(err => console.log(err))
        }
    }

    save(e: FormEvent) {
        e.preventDefault()

        if(!this.element.icon || this.element.icon === '') {
            notification.error({
                message: i.t('def:errors.error'),
                description: i.t('def:errors.icon')
            })
            return false
        }

        if(!__select.select.type || __select.select.type === '') {
            notification.error({
                message: i.t('def:errors.error'),
                description: i.t('def:errors.link_value')
            })
            return false
        }


        const data = new FormData()
        data.append('enabled', `${+ this.element.enabled}`)
        data.append('label', this.element.label)
        data.append('icon', this.element.icon)
        data.append('location', this.type)
        data.append('link_type', __select.select.type)
        if(this.element.warehouse_id) data.append('warehouse_id', `${this.element.warehouse_id}`)

        if(__select.select.type !== '') {
            data.append('refer_type', __select.select.type)

            if(__select.select.type === 'custom_product_list'){
                if(__cpl.selectedLists[0] > 0) {
                    data.append('refer_id', __cpl.selectedLists[0])
                } else {
                   // hasError = true
                }
            } else {
                if(__select.select.value || (__select.select.type === 'category' && __select.select.value === '')) {
                    if(__select.select.type === 'url' || __select.select.type === 'url_blank'){
                        data.append('refer_value', __select.select.value)
                    } else {
                        data.append('refer_id', __select.select.value)
                    }
                }
            }
        }

        let path = `/navigation`
        if(this.element.id && this.element.id > 0)
            path += `/${this.element.id}`

        openApi('post', path, data)
            .then(result => {
                window.location.href = `/settings/navigation/${this.type}`
            })
          .catch(err => {
              console.log(err.response);
              notification.error({
                  message: i.t('def:errors.error'),
                  description: err.response.data.data.message ?? ''
              })
          })

    }

    sorting(id: number, action: string, type: string) {
        let uri = `/navigation/sorting/${id}/${action}/${type}`
        if(this.warehouse) uri += `/${this.warehouse}`
        openApi('put', uri)
            .then(result => {
                this.getList()
            })
    }

    delete(id: number) {
        if(window.confirm(i.t('def:confirms.delete')))
            openApi('delete', `/navigation/${id}`)
                .then(result => {
                    this.getList()
                })
    }

}

export default new __navigation()