import { makeObservable, observable } from 'mobx'
import { FormEvent } from 'react'
import { notification } from 'antd'
import i from '../../translations/i'
import { openApi } from "../../helpers/request";
import dayjs from "dayjs";
import __designer from "../screen_designer/__designer";

class NewsController {

  item_tab = 'main'

  list: any[] = []
  item: any = {
    id: 0,
    enabled: true,
    name: null,
    name_encode: null,
    short_description: null,
    publication_date: dayjs(),
    send_notifications: false,
    date_from: null,
    date_to: null,
    view_type: 'default',
    seo_title: null,
    seo_description: null,
    seo_keywords: null,
    internal_url: null,
    images: [],
    types: [],
    categories: [],
    props: [],
    warehouses: []
  }

  pagination = {
    total: 0,
    limit: 10,
    page: 1,
    offset: 0
  }

  image: any = null

  selectedProperties: any = []

  constructor() {
    makeObservable(this, {
      list: observable,
      item: observable,
      pagination: observable,
      item_tab: observable,
      image: observable,
      selectedProperties: observable
    })
  }

  setPage(page: number) {
    this.pagination.page = page
    this.getList()
  }

  getList() {
    openApi('get', `/news?limit=${this.pagination.limit}&page=${this.pagination.page}&with[]=image`)
      .then(result => {
        console.log(result)
        this.list = result.data
        this.pagination.total = result.total
        this.pagination.page = result.page
      })
      .catch(result => {
        console.log(result.response)
      })
  }

  getOne(id: any) {
    if(id > 0) {
      __designer.place = `news_${id}`;
      __designer.getList()
      openApi("get", `/news/${id}?with[]=types&with[]=categories&with[]=props&with[]=image&with[]=props&with[]=warehouses`)
        .then(result => {
          console.log(result.data)
          this.item = result.data

          this.item.props.map((el: any) => this.selectedProperties.push(el.value_id))
        })
        .catch(result => {
          console.log(result.response);
        });
    }
    else {
      let string = this.generateString()
      __designer.place = `news_${string}`
    }
  }

  delete(id: any) {
    if(window.confirm(i.t('def:confirms.delete')))
      openApi('delete', `/news/${id}`)
        .then(result => {
          this.getList()
        })
        .catch(result => {
          console.log(result.response)
        })
  }

  save() {

    const data = new FormData()
    data.append('enabled', `${+ this.item.enabled}`)
    data.append('name', this.item.name ? `${this.item.name}` : '')
    data.append('name_encode', this.item.name_encode ? `${this.item.name_encode}` : '')
    data.append('view_type', this.item.view_type)
    data.append('short_description', this.item.short_description ? `${this.item.short_description}` : '')
    data.append('send_notifications', `${+ this.item.send_notifications}`)
    data.append('seo_title', this.item.seo_title ? `${this.item.seo_title}` : '')
    data.append('seo_description', this.item.seo_description ? `${this.item.seo_description}` : '')
    data.append('seo_keywords', this.item.seo_keywords ? `${this.item.seo_keywords}` : '')
    data.append('internal_url', this.item.internal_url ? `${this.item.internal_url}` : '')

    if(this.item.publication_date) data.append('publication_date', dayjs(this.item.publication_date).toString())
    if(this.item.date_from) data.append('date_from', dayjs(this.item.date_from).toString())
    if(this.item.date_to) data.append('date_to', dayjs(this.item.date_to).toString())

    data.append('screen_name', __designer.place)

    if(this.image) data.append('image', this.image)

    this.item.types.length > 0 && this.item.types.map((el: any, index: number) => {
      data.append(`types[${index}][type_id]`, `${+ el.type_id}`)
    })

    this.item.warehouses.length > 0 && this.item.warehouses.map((el: any, index: number) => {
      data.append(`warehouses[${index}][warehouse_id]`, `${+ el.warehouse_id}`)
    })

    this.item.categories.length > 0 && this.item.categories.map((el: any, index: number) => {
      data.append(`categories[${index}][category_id]`, `${+ el.category_id}`)
    })

    this.selectedProperties.map((el: any, index: number) => data.append(`props[${index}]`, `${+ el}`))

    openApi('post', `/news/${this.item.id}`, data)
      .then(() => {
        if(this.item.id && this.item.id > 0) {
          this.getOne(this.item.id);
          notification.success({
            message: i.t('def:success.success'),
            description: i.t('def:success.edit')
          })
        }
        else window.location.href = `/news`
      })
      .catch(result => {
        console.log(result.response)
      })
  }

  changeTab(tab: string) {
    this.item_tab = tab
  }

  upload(image: any) {
    this.image = image
    return false
  }

  generateString() {
    let result = '';
    let words = '0123456789qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM';
    let max_position = words.length - 1;
    for (let i = 0; i < 5; ++i) {
      let position = Math.floor(Math.random() * max_position);
      result = result + words.substring(position, position + 1);
    }
    return result;
  }

  selectProperty(id: number) {
    let index = this.selectedProperties.findIndex((el: any) => el === id)

    if(index > -1) {
      this.selectedProperties.splice(index, 1)
    } else {
      this.selectedProperties.push(id)
    }
  }

}

export default new NewsController()